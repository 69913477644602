<template>
  <vs-card class="custom-product-form-table">
    <div slot="header" class="px-5 py-3 flex justify-between items-center">
      <h3>Product List</h3>
      <vs-button class="px-5" @click="handleCreateCustomProduct">Add</vs-button>
    </div>
    <div id="data-list-list-view" class="data-list-container p-5">
      <div class="mb-5">
        <p class="text-lg font-medium">
          Manage the available products your staff can access while scripting
          the app
        </p>
        <div class="text-lg flex flex-no-wrap items-center">
          Toggle
          <vs-switch
            class="m-2 pointer-events-none"
            value="true"
          >
            <span slot="on">On</span>
            <span slot="off">Off</span>
          </vs-switch>
          to display product(s) for use while scripting in app.
        </div>
        <div class="text-lg flex flex-no-wrap items-center">
          Toggle
          <vs-switch
            class="m-2 pointer-events-none"
          >
            <span slot="on">On</span>
            <span slot="off">Off</span>
          </vs-switch>
          to display product(s) for use while scripting in app.
        </div>
      </div>
      <div class="mb-2 flex gap-3">
        <div class="w-full md:w-auto">
          <vs-input
            icon="search"
            style="width: 100%"
            placeholder="Search"
            v-model="searchInputString"
          />
        </div>
        <vs-select
          width="70px"
          placeholder="10"
          autocomplete
          v-model="dataTableParams.limit"
        >
          <vs-select-item
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item, index) in limitOptions"
          />
        </vs-select>
      </div>
      <ul class="lg:hidden">
        <li :key="indextr" v-for="(tr, indextr) in docs">
          <div class="rounded mb-2 p-2" style="border: 1px solid #e8e8e8">
            <div class="p-2">
              <h4 class="mobile-text">
                {{
                  docs[indextr].productName
                    ? docs[indextr].productName
                    : "N/A" | capitalize
                }}
              </h4>
            </div>
            <div>
              <vs-switch
                @change="
                  changeProductStatusHandler(
                    docs[indextr].status,
                    docs[indextr].idToUpdate
                  )
                "
                class="m-2"
                v-model="docs[indextr].status"
              >
                <span slot="on">On</span>
                <span slot="off">Off</span>
              </vs-switch>
            </div>
          </div>
        </li>
      </ul>
      <vs-divider></vs-divider>
      <vs-table
        class="hidden lg:block"
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="docs"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        :noDataText="noDataText"
      >
        <template slot="thead">
          <vs-th sort-key="productName">Product Name</vs-th>
          <vs-th>Status</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td
              :data="data[indextr].productName"
              @click.native="
                handleEditProduct(tr.productId, tr.isCustomProduct)
              "
              class="text-primary"
            >
            <small class="block text-sm text-gray-700 mb-1">{{ tr.isCustomProduct ? "Custom" : "Fresh Clinics" }}</small>
              {{ data[indextr].productName }}
            </vs-td>
            <vs-td>
              <vs-switch
                @change="
                  changeProductStatusHandler(
                    data[indextr].status,
                    data[indextr].idToUpdate
                  )
                "
                v-model="data[indextr].status"
              >
                <span slot="on">On</span>
                <span slot="off">Off</span>
              </vs-switch>
            </vs-td>
            <vs-td>
              <vs-button
                type="border"
                size="small"
                icon-pack="feather"
                :icon="isFreshProduct(tr.isCustomProduct)"
                @click="handleEditProduct(tr.productId, tr.isCustomProduct)"
                class="mr-2"
              ></vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div
        class="m-2 flex md:justify-end justify-center items-center flex-wrap"
        style="height: 35px; padding-bottom: 30px"
      >
        <span class="mr-2">
          {{
            dataTableParams.page * dataTableParams.limit -
            (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="5"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </div>
  </vs-card>
</template>

<script>
import { mapActions } from "vuex";
import OrganizationHelper from '@/mixins/Organization';

export default {
  name: "FranchiseClinicProductsList",
  data() {
    return {
      toggleA: true,
      toggleb: false,
      docs: [],
      totalDocs: 0,
      page: 1,
      noDataText: "",
      currentPage: 1,
      searchInputString: null,
      dataTableParams: {
        search: "",
        sort: "productId",
        dir: "desc",
        page: 1,
        limit: 25,
        organizationId: null,
      },
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      serverResponded: false,
      awaitingSearch: null,
    };
  },
  mixins: [OrganizationHelper],
  methods: {
    ...mapActions("organization", ["fetchProductsList", "changeProductStatus"]),
    ...mapActions("product", ["fetchProductsV2"]),
    isFreshProduct(isDefault) {
      if (!isDefault) return "icon-eye";
      return "icon-edit";
    },
    handleCreateCustomProduct() {
      this.$router.push({ name: this.getRoleRouteName("CustomProductsListCreate") });
    },
    handleEditProduct(id, isCustomProduct) {
      this.$router.push({
        name: this.getRoleRouteName("CustomProductsListDetails"),
        params: {
          productId: id,
        },
        query: {
          isCustomProduct,
        }
      });
    },
    async fetchList() {
      this.$vs.loading();
      this.fetchProductsV2(this.dataTableParams)
        .then((res) => {
          this.docs = res.data.data[0].docs;
          if (res.data.data[0].pagination[0]) {
            this.totalDocs = res.data.data[0].pagination[0].total || 0;
            this.page = res.data.data[0].pagination[0].page;
            this.currentPage = res.data.data[0].pagination[0].page;
          }
          this.noDataText = "No Data Available";
          this.serverResponded = true;
          this.$vs.loading.close();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.fetchList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.fetchList();
    },
    handleSort(key, active) {
      if (!key) return;

      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.fetchList();
    },
    changeProductStatusHandler(status, idToUpdate) {
      this.changeProductStatus({
        status,
        idToUpdate,
        organizationId: this.dataTableParams.organizationId,
      });
    },
  },
  watch: {
    searchInputString: function (newVal, oldVal) {
      this.handleSearch(newVal);
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.fetchList();
      }
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  created() {
    this.noDataText = "Loading...";
    let orgId = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
    );
    this.dataTableParams.organizationId = orgId;
    this.fetchList();
  },
};
</script>
<style lang="scss">
.mobile-text {
  color: #164d3d;
}
.custom-product-form-table {
  .vs {
    &-con {
      &-tbody {
        border: none !important;
      }
    }

    &-card--content {
      padding: 0;
      border-top: 1px solid #f8f8f8 !important;
    }

    &-table {
      &--tbody-table {
        min-width: unset !important;
      }
      &--header {
        padding: 0 15px;
      }
      td {
        &:first-child {
          width: 85%;
        }
      }
    }
  }
}
</style>
